@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

// $border-color: #d1d1d1;
// $label-color: #acacac;
// $member-color: #636363;
// $border: 1px solid $border-color;

.post-shift {
  &__container {
    @include make-container();
  }

  &__row {
    @include make-row();
    margin-bottom: 2rem;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__form-col {
    @include make-col-ready();
  }
}

.modal-components {
  &__col {
    &--reach {
      @include make-col(6);

      @media (max-width: $post-shift-xs) {
        @include make-col(12);
      }
    }
    //border: 1px solid red;
  }
}
.post-shift {
  &__dtp-wrap {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    &--show {
      display: block;
    }
  }

  &__comments {
    width: 100%;
    height: 77px;
    border: $border;
    font-family: $sf-pro;
    font-weight: 300;
    font-size: 1.14rem;
    padding: 0.5rem;
  }

  &__job-tip {
    width: 100px;
    height: 40px;
    border: $border;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;

    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%; //above 5 lines make text fit label width
    //border: 1px solid blue;
  }

  &__col-wrap {
    border: $border;
    max-height: 115px;

    @media (max-width: $post-shift-md) {
      max-height: 200px;
    }

    &--groups {
      @media (max-width: $post-shift-xs) {
        border-top: none;
      }
    }

    &--groups {
      overflow-y: auto;
      min-height: 37px;
    }
  }

  &__radio-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: $border;
    @media (max-width: $shifts-md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &--my-team {
      @media (max-width: $shifts-md) {
        border-bottom: none;
      }
    }

    &--groups {
      @media (max-width: $post-shift-xs) {
        border-top: none;
      }
    }
  }

  &__member-count {
    font-family: $sf-pro;
    font-weight: 300;
    font-size: 1rem;
    color: $label-color;
    padding-right: 3rem;
    @media (max-width: $shifts-md) {
      padding-right: 0;
      padding-left: 23px;
    }
  }

  &__team-list {
    @include make-container();
    max-height: 75px;
    overflow-y: scroll;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    // @media (max-width: $post-shift-md) {
    //   max-height: 139px;
    // }

    @media (max-width: $shifts-md) {
      display: none;
    }
  }

  &__team-list-wrap {
    @include make-row();
  }

  &__team-list__member {
    @include make-col-ready();
    @include make-col(6);
    font-family: $sf-pro;
    font-size: 14px;
    font-weight: 300;
    color: $member-color;
    margin-bottom: 0.75rem;
    line-height: 16px;

    @media (max-width: $ipad-portrait) {
      @include make-col(6);
    }
    @media (max-width: $post-shift-md) {
      // @include make-col(12);
    }
  }

  &__btn-call-off {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 16px;
    font-weight: 300;

    color: white;
    padding: 8px;
    margin-right: 15px;
    width: 190px;
    height: 100%;
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid rgba(130,130,130,255);
    color: rgba(130,130,130,255);
    cursor: pointer;

    &:hover {
      background-color: rgba(130,130,130,255);
      border: none;
      color: white;
    }
  }
  &__btn-delete {
   @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 16px;
    font-weight: 300;
    color: red;
    border-bottom: 2px solid red;
  }
  &__label-bold {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 16px;
    font-weight: 300;
  }

  &__submit-btn {
    @extend %button-reset;
    background-color: lighten($primary-blue, 20%);
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;
    color: white;
    padding: 1rem;
    width: 280px;
    border-radius: 100px;
    cursor: default;

    @media (max-width: $post-shift-xs) {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    &--active {
      background-color: $primary-blue;
      cursor: pointer;
    }

    &--post {
      background-color: $primary-blue;
      cursor: pointer;
      width: auto;
      min-width: 112px;

      &:disabled {
        background-color: $button-inactive-color;
      }

      @media (max-width: $post-shift-xs) {
        width: 100%;
      }
    }
  }
  &__top-row-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 24px;
    //border: 1px solid blue;
  }
  &__top-row-item {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 50px;
    
    &:not(:first-child) {
      width: 20%;
      //padding-left: 30px;
      margin-right: 0px;
    }
    &:last-child {
      width: 30%;
      padding-left: 30px;
      margin-right: 0px;
    }
    //border: 1px solid green;
  }
}

.shiffy-header {
  height: 112px;
  background-color: $primary-blue;
  position: relative;
  overflow: hidden;

  &__clouds {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96%;
    max-width: 575px;
    height: 100px;
    transform: translate(-50%, -50%);
  }

  &__mountains {
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 96%;
    max-width: 575px;
    height: 106px;
    transform: translate(-50%);

    @media (max-width: $date-time-sm) {
      bottom: -24px;
    }
  }

  &__shiffy {
    position: absolute;
    left: 50%;
    bottom: -16px;
    transform: translate(-50%);
    width: 80px;
    height: auto;
  }

  @media (max-height: $modal-height-break) {
    height: 90px;
  }

  .close-modal {
    @media (max-height: $modal-height-break) {
      top: 3rem;
    }
    @media (max-width: $post-shift-xs) {
      top: 2rem;
      right: 2rem;
    }
  }
}
