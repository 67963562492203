@import '~_stylesheets/vars';

.modal-wrapper {
  padding: 4rem;
  padding-bottom: 3rem;

  @media (max-width: $shifts-sm) {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  @media (max-width: $post-shift-xs) {
    padding: 0;
    padding-bottom: 1rem;
  }
}
