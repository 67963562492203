@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

$locationButtonBorderColor: #ededed;
$locationButtonBackgroundColor: #fafafa;

.location-page {
  cursor: default;
  flex: 1;
  padding: $grid-gutter-width;
  position: relative;

  margin: 0 auto;

  // &__row {
  //   @include make-row();
  // }

  &__back-button {
    position: absolute;
    left: 0;
    line-height: 50px;
    cursor: pointer;

    @media (max-width: $shifts-sm) {
      position: relative;
      display: inline-block;
    }
  }

  &__header {
    width: 100%;
    color: $grey-dark-very;
    font-size: 32px;
    text-align: center;

    &-subtext {
      color: $grey-medium;
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }

  &__form-group {
    margin: 25px auto 0 auto;
    width: 100%;
    max-width: 400px;

    & .location-page--error {
      border-bottom: 2px solid $primary-red;
    }

    & .location__input-error {
      margin-top: 5px;
      color: $primary-red;
    }
  }

  .location__form-action {
    width: 100%;
    margin-top: 25px;
    text-align: center;
  }
}
