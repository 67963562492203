@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

$anim-dur: 0.33s;
$pad: 1.0rem;

.shift-call-off {
  &__header {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 16px;
    font-weight: 600;
  }

  &__line-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    //border: 1px solid blue;
  }

  &__date-time-label-wrap {
    display: flex;
    flex-direction: row;
    height: 25px;
    //border: 1px solid red;
  }

  &__label {
    width: 250px;
    height: 100%;
    //padding-left: 10px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    //border: 1px solid red;
  }

  &__date-time-button-wrap {
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  }
  
  &__date-picker {
    width: 250px;
    flex: 1;

    table {
      width: 100%;
    }

    thead {
      background-color: $primary-blue;
      color: white;
      text-align: center;

      .rdtPrev,
      .rdtNext {
        cursor: pointer;
        height: 36px;
      }

      .rdtSwitch {
        font-family: $sf-pro;
        font-size: 1rem;
        font-weight: 600;
      }

      .dow {
        font-family: $sf-pro;
        font-size: 0.86rem;
        font-weight: 300;
        padding-bottom: 0.25rem;
      }
    }

    tbody {
      .rdtDay {
        font-family: $sf-pro;
        font-size: 0.86rem;
        text-align: center;
        padding: 7px 0;
        cursor: pointer;

        &.rdtDisabled {
          color: $grey-light;
          cursor: not-allowed;
        }

        &.rdtToday {
          color: $primary-blue;
        }

        &.rdtActive {
          outline: 1px solid $primary-green;
          background-color: rgba($primary-green, 0.38);
        }
      }
    }
  }
  &__job-title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 300;
    height: 38px;
    min-width: 100px;
    padding-top: 7px;
    padding-left: 5px;
    border: 1px solid rgba(210,210,210,255);
  }
  &__date-picker-and-span {
    display: flex;
    flex-direction: column;
    margin-top: -35px;
    margin-left: 78px;
    //border: 1px solid red;
  }
  &__date-picker-wrap {
    /*
    display: flex;
    margin-top: -135px;
    margin-left: 78px;

    border: 1px solid red;
    */
  }
  &__submit-btn {
    @extend %button-reset;
    background-color: rgba(191,191,191,255);
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 1rem;
    width: 174px;
    border-radius: 100px;
    cursor: default;

    @media (max-width: $post-shift-xs) {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    &--active {
      background-color: $primary-blue;
      cursor: pointer;
    }

    &--post {
      background-color: $primary-blue;
      cursor: pointer;
      width: auto;
      min-width: 112px;

      &:disabled {
        background-color: $button-inactive-color;
      }

      @media (max-width: $post-shift-xs) {
        width: 100%;
      }
    }
  }
  &__button {
    @extend %button-reset;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: rgba(61,174,255,255);
    padding: 1rem;
    width: 240px;
    border-radius: 100px;
    cursor: default;
    border: 1px solid rgba(61,174,255,255);
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;

    &:hover {
      color: rgba(61,174,255,255);
      background-color: transparent;
    }
  }
  &__button-create {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    max-height: 132px;
    max-width: 358px;
    padding: $pad;
    position: relative;
    margin-bottom: 2.75rem;

    border-radius: 10px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    transition: box-shadow $anim-dur;

    @media (max-width: $shifts-sm) {
      max-width: none;
    }

    &:hover {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
      transition: box-shadow $anim-dur;
      border: 1px solid rgba(25,148,255,255);
    }

    &:focus {
      border: 1px solid rgba(25,148,255,255);
      //background-color: red;
    }
  }
  &__item-wrap {
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  &__button-wrap {
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  &__reason-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 40%;
    //border: 1px solid red;
  }
  &__reasons-1 {
    width: 100%;
    height: 120px;
    margin-right: 20px;
    overflow-y: scroll;
    border: 1px solid rgba(209,209,209,255);
  }
  &__reasons-2 {
    width: 100%;
    height: 120px;
    margin-right: 20px;
    overflow-y: scroll;
    border: 1px solid rgba(209,209,209,255);
  }
  &__bottom-wrap {
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: rgba(242,242,248,255);
    padding-top: 10px;
    padding-left: 10px;
    //border: 1px solid red;
  }
  &__shift-cards-wrap {
    height: 200px;
    overflow-y: scroll;

    &:not(:first-child) {
      //border: 1px solid blue;
    }
  }
  &__item-h-wrap {
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  &__start-time-error {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 300;
    color: red;
  }
}

.item {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  min-width: 200px;
  //border: 1px solid red;
  &__name {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    color: rgba(147,147,147,255);
    //border: 1px solid red;
  }
  &__value {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 700;
    color: black;
    //border: 1px solid red;
  }
}

.item-h{
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-left: 30px;
  padding-top: 5px;
  min-width: 200px;
  //border: 1px solid red;
  &__value {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 300;
    color: rgba(147,147,147,255);
    //border: 1px solid blue;
  }
  &__name {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin-right: 5px;
    //border: 1px solid blue;
  }
}

