@import '../vars';

@keyframes boxShadowLoadingPending {
  0% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
  40% {
    box-shadow: 0 0 0 2px $primary-blue;
  }
  100% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
}

@-o-keyframes boxShadowLoadingPending {
  0% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
  40% {
    box-shadow: 0 0 0 2px $primary-blue;
  }
  100% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
}

@-moz-keyframes boxShadowLoadingPending {
  0% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
  40% {
    box-shadow: 0 0 0 2px $primary-blue;
  }
  100% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
}

@-webkit-keyframes boxShadowLoadingPending {
  0% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
  40% {
    box-shadow: 0 0 0 2px $primary-blue;
  }
  100% {
    box-shadow: 0 0 15px 2px $primary-blue;
  }
}
