@import '~_stylesheets/vars';

$container-padding: 5px 25px;
$container-font-size: 16px;
$container-max-width-large: 480px;
$container-max-width-med: 320px;

.single-sign-on-box {
  background: white;
  margin: auto;
  width: $container-max-width-large;
  border-radius: $container-border-radius;

  @media (max-width: $shifts-sm) {
    width: $container-max-width-med;

    &__header {
      & > img {
        max-width: $container-max-width-med;
      }
    }
  }

  @media (max-width: $post-shift-xs) {
    width: 100%;
  }

  &__header {
    background-color: $primary-blue;
    text-align: center;
    width: 100%;

    & img {
      max-width: $container-max-width-large;
    }

    &--text {
      padding: $container-padding;
      color: white;
    }
  }

  &__body {
    padding: 15px;
    text-align: center;
    margin-bottom: 25px;

    &--text {
      font-size: 16px;
      padding: $container-padding;
    }
  }

  &__footer {
    text-align: center;
    height: 55px;
    padding: $container-padding;
  }

  &__button {
    transition: all 250ms ease;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-weight: 600;
    padding: 12px 32px;

    border: 1px solid $primary-blue;
    background-color: white;
    border-radius: 20px;
    color: $primary-blue;

    font-size: 18px;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      background-color: $primary-blue;
      color: white;
    }
  }
}
