@import '~_stylesheets/vars';
.dt-picker {
  display: flex;
  width: 514px;
  @media (max-width: $date-time-sm) {
    flex-direction: column;
    width: 100%;
  }

  &__date-picker {
    flex: 1;

    table {
      width: 100%;
    }

    thead {
      background-color: $primary-blue;
      color: white;
      text-align: center;

      .rdtPrev,
      .rdtNext {
        cursor: pointer;
        height: 36px;
      }

      .rdtSwitch {
        font-family: $sf-pro;
        font-size: 1rem;
        font-weight: 600;
      }

      .dow {
        font-family: $sf-pro;
        font-size: 0.86rem;
        font-weight: 300;
        padding-bottom: 0.25rem;
      }
    }

    tbody {
      .rdtDay {
        font-family: $sf-pro;
        font-size: 0.86rem;
        text-align: center;
        padding: 7px 0;
        cursor: pointer;

        &.rdtDisabled {
          color: $grey-light;
          cursor: not-allowed;
        }

        &.rdtToday {
          color: $primary-blue;
        }

        &.rdtActive {
          outline: 1px solid $primary-green;
          background-color: rgba($primary-green, 0.38);
        }
      }
    }
  }

  &__time-picker {
    flex: 1;
  }
}

