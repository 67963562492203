@import '~_stylesheets/vars';

.profile-departments {
  flex: 1;
  height: auto;
  border: 0;
  font-size: 1.3rem;
  font-family: $sf-pro;
  outline: none;
  //border: 1px solid red;

  &__wrap {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    min-height: 75px;
    padding-top: 25px;
    padding-bottom: 45px;
    border-bottom: 2px solid rgba(209,209,209,255);
    //border: 1px solid green;
  }

  &__icon {
    margin-top: 3px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    //border: 1px solid red;
  }

  &__department {
    color: black;
    background-color: rgba(242,242,248,255);
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    margin-right: 8px;
    //border: 1px solid red;
  }

  &__label {
    height: 30px;
  }

  &::placeholder {
    color: $label-color;
    opacity: 1;
  }
}
