@import '~_stylesheets/vars';

.login__footer {
  margin-top: 30px;
}

.login__form-error-text {
  max-width: 500px;
  min-width: 320px;
  width: 100%;
  min-height: 20px;
}

form.login__form {
  margin-top: 100px;
}

.login {
  & > p {
    text-align: center;
  }
}
