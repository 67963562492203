@import '~_stylesheets/vars';

.profile-input {
  margin-bottom: 2.25rem;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid $border-color;

    &--error {
      border-color: $primary-red;
    }
  }

  &--focus {
    border-color: $primary-blue;
  }

  &__icon {
    padding-right: 1.5rem;
  }

  &__error {
    display: block;
    font-family: $sf-pro;
    font-size: 1rem;
    color: $primary-red;
    flex-basis: 100%;
    padding-top: 0.25rem;
  }
}
