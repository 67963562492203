@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

$gutter-available-shift: $grid-gutter-width / 2;

.index-page {
  @extend %route-wrap;
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  flex: 1 1 auto;
  //background-color: $app-bg-color;
  background-color: rgba(242,242,248,255);

  &__wrapper {
    @include make-container-max-widths(
      $max-widths: $container-max-widths,
      $breakpoints: $grid-breakpoints
    );
    width: 100%;
    // height: 100%;
    padding: $grid-gutter-width;
    margin: 0 auto;
    flex: 1 1 auto;
    // background-color: $app-bg-color;

    @media (max-width: $date-time-sm) {
      padding: 1.5rem;
    }

    &--white-bg {
      background-color: white;
    }
  }

  &__row {
    @include make-row();
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: $app-bg-color;
    background-color: rgba(242,242,248,255);
  }

  &__pending-location {
    font-family: $fontFamily;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      max-width: 530px;
    }
  }
}
