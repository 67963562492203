@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

.confirm-shift {
	
	&__btn-delete {
	 @font-face {
	  font-family: 'AvertaBold';
	  src: url('/fonts/averta/Averta-Bold.ttf');
	  }
	  font-family: 'AvertaBold';
	  font-size: 16px;
	  font-weight: 300;
		color: red;
		border-bottom: 2px solid red;
	}

	&__btn-cancel {
		@font-face {
	  font-family: 'AvertaBold';
	  src: url('/fonts/averta/Averta-Bold.ttf');
	  }
	  font-family: 'AvertaBold';
	  font-size: 16px;
	  font-weight: 300;

	  color: white;
    //padding: 1rem;
    padding: 8px;
    width: 174px;
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid rgba(130,130,130,255);
    color: rgba(130,130,130,255);
    cursor: pointer;

    &:hover {
      background-color: rgba(130,130,130,255);
      border: none;
      color: white;
    }
	}

	 &__top-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__item-wrap {
    display: flex;
    flex-direction: column;
    min-width: 16%;
    margin-right: 20px;
  }

  &__item-name {
    color: rgba(128,133,139,255);
    font-size: 14px;
    font-weight: 300;
  }
  &__item-value {
    color: black;
    font-size: 14px;
    font-weight: 500;

  }
}
