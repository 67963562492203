@import '~_stylesheets/vars';

p.login__form-errors {
  &--primary-red {
    color: $primary-red;
  }
}

.confirmation-description {
  text-align: center;
}
