@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

.period-btn {
  @extend %button-reset;
  width: 36px;
  height: 36px;
  font-family: $sf-pro;
  font-size: 1rem;
  font-weight: 600;
  background-color: $button-inactive-color;
  color: $label-color;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;

  &:last-of-type {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  &--active {
    background-color: $primary-blue;
    color: white;
  }
}
