.rto-text-bold {
	color: black;
	font-weight: 500;
}

.rto-card-new {
  
  &__status-bar-orange {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(245,102,56,255);
    border-radius: 3px;
    border: 2.5px solid rgba(245,102,56,255);
  }
}