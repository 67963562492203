@import './vars';
@import './helpers/animations';

@mixin buttonBoxShadow($color) {
  box-shadow: 1px 1px 15px 1px $color;
}

@mixin inverseButton($color) {
  color: $color;
  background-color: white;
}

%button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

%route-wrap {
  margin-top: 97px;
  margin-bottom: 40px;

  @media (max-width: $post-shift-xs) {
    margin-top: 64px;
  }
}

%inner-wrap {
  @include make-container-max-widths(
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
  );
  width: 100%;
  // height: 100%;
  padding: $grid-gutter-width;
  margin: 0 auto;
  flex: 1 1 auto;
  // background-color: $app-bg-color;

  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }

  &--white-bg {
    background-color: white;
  }
}

@mixin input-placeholder(
  $icon-path,
  $text-indent: 35px,
  $background-position-y: 10px,
  $background-position-x: 5px
) {
  background-image: url($icon-path);
  background-repeat: no-repeat;
  background-position-y: $background-position-y;
  background-position-x: $background-position-x;
  text-indent: $text-indent;
}
