@import '../vars';
@import '../mixins';

button {
  &.btn {
    transition: opacity ease 450ms, color ease 450ms, border ease 450ms,
      box-shadow ease 450ms;

    border: none;
  }

  &.btn-deny {
    box-sizing: border-box;

    opacity: 0.4;
    color: $primary-red;
    border: 1px solid $primary-red;
    background-color: transparent;

    &:hover {
      opacity: 0.8;
    }
  }

  &.btn-danger {
    color: white;
    background-color: $primary-red;

    &:hover {
      @include buttonBoxShadow(rgba(252, 88, 88, 0.5));
    }

    &.btn-inverse {
      @include inverseButton($primary-red);
    }
  }
}

.btn {
  font-size: 14px;
  font-weight: 200;

  padding: 13px 30px;

  transition: box-shadow 450ms;
  text-decoration: none;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  border-radius: $button-border-radius;

  &:hover {
    cursor: pointer;
  }
}

.btn-approve {
  color: white;
  background-color: $primary-blue;

  &:hover {
    @include buttonBoxShadow(rgba(33, 194, 253, 0.5));
  }

  &.btn-inverse {
    @include inverseButton(rgb(33, 194, 253));
  }
}

.btn-deny {
  border: 1px solid $grey-medium;
  background-color: transparent;

  &.btn-inverse {
    @include inverseButton(rgb(33, 194, 253));
  }
}

.btn-primary {
  opacity: 0.56;
  color: black;
  background-color: transparent;

  &.active {
    opacity: 1;
    color: white;
    background-color: black;

    @include buttonBoxShadow(rgba(0, 0, 0, 0.5));
  }
}

.button {
  cursor: pointer;
  font-size: 24px;
  line-height: 28px;
  border-radius: 100px;
  font-weight: 600;
  padding: 12px 32px;
  transition: background-color ease 250ms, color ease 250ms;

  &.button-primary {
    background-color: $primary-blue;
    border: 1px solid $primary-blue;
    font-weight: 600;
    color: white;

    &.button-inverse {
      background-color: white;
      border: 1px solid $primary-blue;
      color: $primary-blue;

      &:hover {
        background-color: $primary-blue;
        color: white;
      }
    }

    &:hover {
      background-color: white;
      color: $primary-blue;
    }
  }

  &.button-deny {
    border: 1px solid $primary-red;
    color: white;
    background-color: $primary-red;

    &:hover {
      background-color: white;
      color: $primary-red;
    }
  }

  &.button-approve {
    border: 1px solid $primary-blue;
    color: white;
    background-color: $primary-blue;

    &:hover {
      background-color: white;
      color: $primary-blue;
    }
  }

  &.button-small {
    font-size: 14px;
    padding: 7px 18px;
    line-height: 16px;

    &:hover {
      text-decoration: none;
    }
  }
}
