@import '~_stylesheets/vars';

.verified-badge {
  width: 67px;
  display: flex;
  justify-content: center;

  &__label {
    font-family: $fontFamily;
    font-size: 0.86rem;
    margin-right: 0.5rem;
    flex: 1;

    &--verified {
      color: $primary-green;
    }

    &--unverified {
      color: $primary-red;
    }
  }
}
