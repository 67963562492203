@import '~_stylesheets/vars';

.login__link {
  cursor: pointer;
  margin-left: 5px;
  color: $primary-blue;

  &:hover {
    text-decoration: none;
    color: black;
  }
}

.login__form-error-text--center {
  text-align: center;
}
