@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

$pad: 1.5rem;

.time-picker {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: $border;
  @media (max-width: $date-time-sm) {
    height: 246px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__row {
    padding-left: $pad;
    padding-right: $pad;

    &--footer {
      padding: 0;
    }
  }

  &__label {
    font-family: $sf-pro;
    font-weight: 300;
    font-size: 1rem;
    color: $label-color;
    margin-bottom: 4px;
  }

  &__time-wrap {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__time-input {
    width: 48px;
    height: 34px;
    font-family: $sf-pro;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
    border: $border;
    &:focus {
      outline: 0;
    }

    &--error {
      border-color: $primary-red;
    }
  }

  &__time-error {
    position: absolute;
    bottom: -19px;
    left: 0;
    margin-bottom: 0;
    font-family: $sf-pro;
    font-size: 0.86rem;
    color: $primary-red;
  }

  &__colon {
    font-family: $sf-pro;
    font-size: 24px;
    font-weight: 600;
    color: $border-color;
    margin: 0 0.25rem;
  }

  &__period-wrap {
    margin-left: 0.75rem;
  }

  &__footer {
    border-top: $border;
    padding-top: 1rem;
    padding-left: $pad;
    padding-right: $pad;
  }

  &__action-btn {
    @extend %button-reset;
    border-radius: $radius;
    font-family: $sf-pro;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.75rem;

    &--clear {
      background-color: white;
      border: $border;
      color: $label-color;
      width: 40%;
      margin-right: 10%;
    }

    &--apply {
      background-color: $primary-blue;
      border: 1px solid $primary-blue;
      color: white;
      width: 50%;

      &--inactive {
        background-color: lighten($primary-blue, 20%);
        border-color: lighten($primary-blue, 20%);
        cursor: default;
      }
    }
  }
}
