@import '~_stylesheets/vars';

.loading-spinner {
  animation: rotate 0.8s infinite linear;
  border-style: solid;
  border-radius: 50%;

  &--small {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 0.2rem;
  }

  &--medium {
    height: 4rem;
    width: 4rem;
    border-width: 0.5rem;
  }

  &--large {
    height: 6rem;
    width: 6rem;
    border-width: 0.75rem;
  }

  &--red {
    border-color: $primary-red;
    border-right-color: transparent;
  }

  &--blue {
    border-color: $primary-blue;
    border-right-color: transparent;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
