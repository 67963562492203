@import '~_stylesheets/vars';

.location-container {
  max-width: 400px;
  width: 100%;
  margin: 35px auto 0 auto;
}

.location-loading {
  text-align: center;
}

.location-summary {
  text-align: center;
}

.location-not-found {
  text-align: center;
  color: $primary-red;
}

.location-summary__join-error {
  color: $primary-red;
  text-align: center;
}
