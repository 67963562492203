@import '~_stylesheets/vars';

.profile-input-text {
  flex: 1;
  height: auto;
  border: 0;
  font-size: 1.3rem;
  font-family: $sf-pro;
  outline: none;

  &::placeholder {
    color: $label-color;
    opacity: 1;
  }
}
