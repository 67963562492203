@import '~_stylesheets/vars';

.hamburger {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  cursor: pointer;
  height: 28px;

  @media (max-width: $post-shift-md) {
    display: block;
  }

  span {
    display: block;
    height: 5px;
    width: 100%;
    background: $primary-red;
    position: absolute;
    border-radius: 100px;
    transform-origin: center;
    transition: all 0.25s;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &--open {
    span:nth-child(1) {
      transform: rotate(-135deg);
      top: 10px;
    }
    span:nth-child(2) {
      background-color: transparent;
    }
    span:nth-child(3) {
      transform: rotate(135deg);
      top: 10px;
    }
  }
}
