@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';

.profile-page {
  width: 100%;

  &__wrapper {
    width: 100%;
    padding: $grid-gutter-width;

    @media (max-width: $date-time-sm) {
      padding: 1.5rem;
    }

    &--white-bg {
      background-color: white;
    }
  }

  &__photo-col {
    @include make-col-ready();
    @include make-col-offset(2);
    @include make-col(3);

    @media (max-width: $shifts-md) {
      @include make-col-offset(0);
      @include make-col(4);
    }

    @media (max-width: $date-time-sm) {
      @include make-col(12);
    }

    &__photo {
      width: 100%;
      height: auto;

      @media (max-width: $date-time-sm) {
        display: block;
        max-width: 200px;
        margin: 0 auto;
        padding-bottom: 2rem;
      }
    }
  }

  &__info-group {
    margin-bottom: 3rem;
  }

  &__info-col {
    @include make-col-ready();
    @include make-col(5);

    @media (max-width: $shifts-md) {
      @include make-col(8);
    }

    @media (max-width: $date-time-sm) {
      @include make-col(12);
    }
  }

  &__info-label {
    font-family: $fontFamily;
    font-size: 2rem;
    font-weight: bold;
    color: $grey-dark;
  }

  &__info-details {
    font-family: $fontFamily;
    color: $grey-medium;
    margin-bottom: 2rem;
  }

  &__reset-password-link {
    font-family: $fontFamily;
    font-size: 0.86rem;
    color: $primary-blue;
    text-decoration: underline;
    margin-top: -1rem;
    margin-bottom: 2rem;
    display: block;
  }

  &__submit-message {
    font-family: $fontFamily;
    margin-bottom: 1rem;
    color: $primary-green;

    &--error {
      color: $primary-red;
    }
  }
}
