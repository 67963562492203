@import '~_stylesheets/components/_ModalComponents';
@import '../vars';

.comment {
  display: flex;

  &--sub {
    margin-left: $dimensions;
    padding-top: 10px;
    padding-left: 10px;
    //border: 1px solid blue;
    &__username {
      font-size: 1rem;
    }

    &__text {
      font-size: 1rem;
    }

    &__my {
      &:hover {
        background-color: #F2F2F7;
      }
    }
  }


  &__content {
    width: 90%;
    border-bottom: $border;
    border-color: $comment-border-color;
  }

  &__username {
    @extend .modal-components__label;
    color: black;
    margin-bottom: 0;
    line-height: 1.2rem;
    font-weight: 400;
  }

  &__time {
    @extend .modal-components__label;
    font-size: 0.86rem;
  }

  &__text {
    line-height: normal;
    font-size: 1rem;
    margin-top: 0.5rem;
    color: $comment-color;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
  }
}

.delete-comment {
  height: 220px;
  //border: 1px solid lightgrey;
  &__title {
    font-size: 36px;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;
  }
}
