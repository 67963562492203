@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

.request-time-off {

  &__date-time-label-wrap {
    display: flex;
    flex-direction: row;
    height: 25px;
    //border: 1px solid red;
  }

  &__label {
    width: 250px;
    height: 100%;
    padding-left: 10px;
    font-size: 14px;
    //border: 1px solid red;
  }

  &__date-time-button-wrap {
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  }

  &__radio-items-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  
  &__radio-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: $border;
    border-left: $border;
    //border-right: $border;
    border-right: none;
    min-width: 220px;
    @media (max-width: $shifts-md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:first-child {
      border-top: $border;
    }

     &:last-child {
      maring-bottom: 10px;
    }
    

    &--groups {
      @media (max-width: $post-shift-xs) {
        //border-top: none;
        //margin-bottom: 10px;
      }
    }
    //border: 1px solid green;
  }

   &__radio-item-organic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: $border;
    border-left: $border;
    border-right: $border;
    min-width: 220px;
    @media (max-width: $shifts-md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:first-child {
      border-top: $border;
    }

     &:last-child {
      maring-bottom: 10px;
    }
    

    &--groups {
      @media (max-width: $post-shift-xs) {
        //border-top: none;
        //margin-bottom: 10px;
      }
    }
    //border: 1px solid green;
  }

  &__space {
    height: 12px;
  }

  &__balance-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: $border;
    //border-left: $border;
    border-left: none;
    border-right: $border;
    @media (max-width: $shifts-md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:first-child {
      border-top: $border;
    }

     &:last-child {
      maring-bottom: 10px;
    }
    //border: 1px solid red;
  }

  &__date-picker {
    width: 250px;
    flex: 1;

    table {
      width: 100%;
    }

    thead {
      background-color: $primary-blue;
      color: white;
      text-align: center;

      .rdtPrev,
      .rdtNext {
        cursor: pointer;
        height: 36px;
      }

      .rdtSwitch {
        font-family: $sf-pro;
        font-size: 1rem;
        font-weight: 600;
      }

      .dow {
        font-family: $sf-pro;
        font-size: 0.86rem;
        font-weight: 300;
        padding-bottom: 0.25rem;
      }
    }

    tbody {
      .rdtDay {
        font-family: $sf-pro;
        font-size: 0.86rem;
        text-align: center;
        padding: 7px 0;
        cursor: pointer;

        &.rdtDisabled {
          color: $grey-light;
          cursor: not-allowed;
        }

        &.rdtToday {
          color: $primary-blue;
        }

        &.rdtActive {
          outline: 1px solid $primary-green;
          background-color: rgba($primary-green, 0.38);
        }
      }
    }
  }
}

.request-time-off {
  &__tp-wrap {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    &--show {
      display: block;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  //border: 1px solid red;
  &__name {
    color: rgba(147,147,147,255);
    //border: 1px solid red;
  }
  &__value {
    color: black;
    //border: 1px solid red;
  }
}

.balance-of-types {
  width: 300px;
  height: 16px;
  font-size: 14px;
  margin-left: 200px;

  //border: 1px solid blue;
}

.button-delete {
  width: 200px;
  height: 36px;
  border-radius: 18px;
  color: black;
  background-color: rgba(242,242,247,255);
  font-size: 14px;
  font-weight: 600;


  &:last-child {
    margin-left: 30px;
  }

  &:hover {
    color: black;
    background-color: rgba(242,242,247,255);
    border: 1px solid black;
  }

  //border: 1px solid red;
}

.button-confirm {
  width: 180px;
  height: 36px;
  border-radius: 18px;
  color: white;
  background-color: rgba(233,100,94,255);
  font-size: 14px;
  font-weight: 600;


  &:last-child {
    margin-left: 30px;
  }

  &:hover {
    color: black;
    background-color: white;
    border: 1px solid rgba(233,100,94,255);
  }

  //border: 1px solid red;
}


.status-label {
  width: 200px;
  height: 36px;
  border-radius: 18px;
  color: rgba(255,146,9,255);
  background-color: rgba(255,239,217,255);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-top: 5px;
  margin-left: 10px;


  &:hover {
    //color: black;
    //background-color: white;
    //border: 1px solid black;
  }

  //border: 1px solid red;
}

.up-arrow {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url('/icons/up-arrow.svg');
  background-position: center;
  background-size: 40%; /* 100 To fill the dimensions of the button */

  &:hover {
    border: 1px solid rgba(255,154,9,255);
    border-radius: 4px;
    background-color: rgba(246,222,196,255);
  }
  
}
.down-arrow {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url('/icons/down-arrow.svg');
  background-position: center;
  background-size: 30%; /* 100 To fill the dimensions of the button */

  &:hover {
    border: 1px solid rgba(255,154,9,255);
    border-radius: 4px;
    background-color: rgba(246,222,196,255);
  }
  //border: 1px solid red;
}

.title-wrap {

  //border: 1px solid blue;
}

.submitted-time {
  margin-top: 0px;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: rgba(147,147,147,255);
  //border: 1px solid red;
}

