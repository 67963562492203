@import '~_stylesheets/vars';

.radio-option {
  display: none;
}

.existing-account {
  background-color: lighten($grey-light-very, 15%);
  display: inline-block;
  float: left;
  cursor: pointer;
  width: 270px;
  height: 220px;
  border: 1px solid $border-color;
  margin: 0 20px 20px 0;
  padding: 5px 15px;

  transition: box-shadow 250ms ease;

  &__indicator {
    width: 100%;
    margin-top: 24px;
  }

  & .indicator {
    background-color: white;
    border-radius: 50%;
    border: 1px solid $grey-light-very;
    height: 45px;
    width: 45px;
    padding-top: 3.5px;
    margin: auto;

    & span {
      line-height: 45px;
    }

    & > svg.icon.checkmark {
      width: 30px;
      height: 30px;
    }

    &__checked {
      background-color: $primary-blue;
    }
  }

  &:hover {
    box-shadow: 9px 7px 16px 1px rgba(0, 0, 0, 0.1);
  }

  &__details {
    color: $grey-dark;
    margin-top: 35px;
  }

  &__name {
    text-align: center;
  }

  &__phone {
    margin-top: 15px;
    text-align: center;
  }
}
