@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

$anim-dur: 0.33s;
$pad: 1.0rem;

.inventory-card {
  width: 100%;
  max-height: 132px;
  max-width: 358px;
  padding: $pad;
  //padding-left: 0px;
  position: relative;
  margin-bottom: 2.75rem;

  border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  //background-image: url('assets/shift-card-bg.svg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  transition: box-shadow $anim-dur;

  @media (max-width: $shifts-sm) {
    max-width: none;
  }

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow $anim-dur;
    border: 1px solid rgba(25,148,255,255);
  }

  &__top-wrap {
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  }
  &__bottom-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: center;
    margin-top: 5px;
    //border: 1px solid green;
  }
  &__status-bar {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: black;
    border-radius: 3px;
    border: 2.5px solid black;
  }
  &__status-bar-blue {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(0,112,251,255);
    border-radius: 3px;
    border: 2.5px solid rgba(0,112,251,255);
  }
  &__status-bar-green {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(0,165,79,255);
    border-radius: 3px;
    border: 2.5px solid rgba(0,165,79,255);
  }
  &__status-bar-orange {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(255,188,1,255);
    border-radius: 3px;
    border: 2.5px solid rgba(255,188,1,255);
  }
  &__status-bar-magenta {
    display: inline-block;
    height:50px;
    width: 5px;
    margin-top: 0px;
    //margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(255,50,168,255);
    border-radius: 3px;
    border: 2.5px solid rgba(255,50,168,255);
  }
  &__top-text-wrap {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
  }
  &__title-type-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    margin-right: 20px;
    min-width: 195px;
    max-width: 195px;
    //border: 1px solid red;
  }
  &__jobTitle {
    /*
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    */
    white-space: nowrap;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;

    &:hover {
      overflow: visible;
    }
    //border: 1px solid blue;
  }
  &__shiftType {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;

    &:hover {
      background-color: white;
      overflow: visible;
      z-index: 2;
    }
    //border: 1px solid blue;
  }
  &__Time-text {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 3px;
    //border: 1px solid blue;
  }
  &__trade-status-posted {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    color: rgba(0,125,255,255);
    background-color: rgba(213,235,255,255);
    width: 80px;
    height: 24px;
    border-radius: 12px;
    //margin-left: 50px;
    padding-top: 3px;
    text-align: center;
  }
  &__trade-status-approved {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    color: rgba(0,203,73,255);
    background-color: rgba(219,248,228,255);
    width: 80px;
    height: 24px;
    border-radius: 12px;
    //margin-left: 50px;
    padding-top: 3px;
    text-align: center;
  }
  &__trade-status-rejected {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    color: rgba(255,75,87,255);
    background-color: rgba(255,226,225,255);
    width: 80px;
    height: 24px;
    border-radius: 12px;
    //margin-left: 50px;
    padding-top: 3px;
    text-align: center;
  }
  &__trade-status-pending {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    color: rgba(255,142,0,255);
    background-color: rgba(255,238,214,255);
    width: 80px;
    height: 24px;
    border-radius: 12px;
    //margin-left: 50px;
    padding-top: 3px;
    text-align: center;
  }
  &__avatars-wrap {
    width: 80px;
    height: 35px;
    position: relative;
    margin-right: 15px;
    //margin-top: 10px;
    //border: 1px solid blue;

  }
  &__trade-status-scheduled {
    //margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0px;
  }
  &__avatars-wrap {
    width: 80px;
    height: 35px;
    position: relative;
    margin-right: 15px;
    //margin-top: 10px;
    //border: 1px solid blue;

  }
  &__avatars-wrap-single {
    //display: flex;
    //flex-direction: row;
    width: 50px;
    height: 35px;
    position: relative;
    margin-right: 15px;
    //margin-top: 10px;
    //border: 1px solid green;
  }
  &__thumb-wrap {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 36px;
    height: 36px;
    z-index: 0;
    background-color: white;
    border-radius: 50%;

    //border: 1px solid red;
    
    &:not(:first-child) {
      left: 25px;
      overflow: hidden;
      z-index: 1;
      //border: 1px solid red;
    }

    &:last-child {
      left: 30px;
      overflow: hidden;
      z-index: 1;
      //border: 1px solid red;
    }
  }
  &__avatar {
    width: 30px;
    height:30px;
    border-radius: 50%;
  }
  &__verified-badge {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 15px;
    height: 15px;
    z-index: 1;
  }
  &__verified-badge-right {
    position: absolute;
    top: 20px;
    left: 45px;
    width: 15px;
    height: 15px;
    z-index: 1;
  }
  &__message {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 300;
    //border: 1px solid blue;
  }
  &__status-incentive-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    //border: 1px solid blue;
  }
  &__incentive-label {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%; //above 5 lines make text fit label width

    //width: 80px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
    padding-top: 3px;
    color: black;
    background-color: rgba(242,242,248,255);
    //background-color: rgba(1,202,72,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    border-radius: 10px;
    text-align: center;
    //overflow: auto;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      overflow: visible;
    }
  }
  &__scheduled-tag-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    white-space: nowrap;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 300;
    color: rgba(44,44,46,255);
    background-color: rgba(241,240,245,255);
    width: 85px;
    height: 24px;
    border-radius: 12px;
  }
}

.colored-circle {
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border: 3px solid black;
}

.colored-circle-small {
  display: inline-block;
  height: 0.5px;
  width: 0.5px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border: 3px solid black;
}

.icon-clock {
    //margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    //color: rgba(209,209,209,255);
    color: rgba(44,44,46,255);
}
