@import '~_stylesheets/mixins';

.close-modal {
  @extend %button-reset;
  position: absolute;
  top: 4rem;
  right: 4rem;
  z-index: 2;

  @media (max-width: $shifts-md) {
    top: 2rem;
    right: 2rem;
  }
}
