@import '~_stylesheets/components/_ModalComponents';
@import '~_stylesheets/mixins';
@import '~_stylesheets/vars';
@import '../vars';

.comment-form {
  display: flex;
  align-items: center;
  margin-left: $dimensions;

  form {
    // display: flex;
    // align-items: center;
    width: 100%;
    height: auto;
  }

  &__form-group {
    display: flex;
  }

  &__input {
    @extend .modal-components__label;
    width: 100%;
    height: $dimensions / 1.5;
    border: $border;
    border-right: none;
    font-size: 1rem;
    color: inherit;
    margin: 0;
    padding: 0 1rem;
    border-radius: 100px 0 0 100px;

    &::placeholder {
      font-size: 0.8rem;
      color: $grey-light;
    }
  }

  &__submit-btn {
    @extend %button-reset;
    height: $dimensions / 1.5;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0 100px 100px 0;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    font-size: 0.88rem;

    &--active {
      color: white;
      background-color: $primary-blue;
    }
  }
}
