@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

$gutter-available-shift: $grid-gutter-width / 2;

.shifts-page {
  &__available-shift {
    width: 50%;
    display: inline-block;
    &:nth-child(even) {
      padding-right: $gutter-available-shift;
      @media (max-width: $shifts-md) {
        padding-right: 0;
      }
    }
    &:nth-child(odd) {
      padding-left: $gutter-available-shift;
      @media (max-width: $shifts-md) {
        padding-left: 0;
      }
    }
    @media (max-width: $shifts-md) {
      width: 100%;
      display: block;
    }
  }

  &__available-shifts {
    @include make-col-ready();
    @include make-col(8);
    padding-left: $grid-gutter-width;
    @media (max-width: $ipad-portrait) {
      padding-left: $gutter-tablet;
    }
    @media (max-width: $shifts-md) {
      @include make-col(6);
      padding-left: $gutter-mobile;
    }
    @media (max-width: $shifts-sm) {
      @include make-col(12);
    }
  }

  &__my-shifts {
    @include make-col-ready();
    @include make-col(4);
    padding-right: $grid-gutter-width;
    @media (max-width: $ipad-portrait) {
      padding-right: $gutter-tablet;
    }
    @media (max-width: $shifts-md) {
      @include make-col(6);
      padding-right: $gutter-mobile;
    }
    @media (max-width: $shifts-sm) {
      @include make-col(12);
    }
    border-right: 1px solid rgba(212,212,217,255);
  }

  &__shift-type {
    font-family: 'SF Pro Display';
    font-weight: 600;
    color: $grey-dark-very;
  }

  &__location-dropdown {
    @include make-col-ready();
    @include make-col(12);
    padding-bottom: 1.5rem;
    display: none;
    @media (max-width: $date-time-sm) {
      display: block;
    }
  }
}
