@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

.delete-shift {
  .modal-components {
    &__submit-btn {
      // width: auto;
    }
    &__col--submit {
      margin-top: 3rem;
    }
  }
}
