@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';

$locationButtonBorderColor: #ededed;
$locationButtonBackgroundColor: #fafafa;

.location-page {
  cursor: default;
  flex: 1;
  padding: $grid-gutter-width;
  position: relative;
  background-color: white;

  margin: 0 auto;

  &__row {
    @include make-row();
  }

  &__header {
    width: 100%;
    color: $grey-medium;
    font-size: 32px;
    text-align: center;

    &-subtext {
      color: $grey-light;
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }

  .location-type__column {
    @include make-col-ready();
    @include make-col(12);

    &--center {
      text-align: center;
    }
  }

  .location-type__button {
    cursor: pointer;
    text-align: center;
    background-color: $locationButtonBackgroundColor;
    width: 260px;
    height: 260px;
    transition: box-shadow 250ms ease;
    margin-top: 2rem;

    &__header {
      font-size: 18px;
      color: $grey-dark-very;
      margin-bottom: 30px;
    }

    &__arrow-container {
      margin: auto;
      margin-top: 30px;
      height: 40px;
      width: 40px;
      background-color: $primary-blue;
      border-radius: 50%;

      span {
        line-height: 45px;
      }
    }

    &:hover {
      box-shadow: 13px 11px 20px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .location-type__next-btn {
    margin-top: 2rem;
  }
}
