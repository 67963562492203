@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/mixins';
@import '~_stylesheets/vars';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 97px;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media (max-width: $ipad-portrait) {
    padding-left: $gutter-tablet;
    padding-right: $gutter-tablet;
  }

  @media (max-width: $iphone-se) {
    padding-left: $gutter-tablet;
    padding-right: $gutter-tablet;
    //border: 1px solid red;
  }

  @media (max-width: $iphone-xr) {
    padding-left: $gutter-tablet;
    padding-right: $gutter-tablet;
    //border: 1px solid red;
  }

  @media (max-width: $post-shift-xs) {
    padding-left: $gutter-mobile;
    padding-right: $gutter-mobile;
    height: 64px;
  }

  p {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    letter-spacing: 0.64px;
  }

  &__wrap {
    @include make-container-max-widths(
      $max-widths: $container-max-widths,
      $breakpoints: $grid-breakpoints
    );
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  &__greeting {
    text-align: center;

    @media (max-width: $date-time-sm) {
      display: none;
    }

    h1 {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 1.42rem;
      padding-left: 0;
      margin-bottom: 0.5rem;
      // @media (max-width: $date-time-sm) {
      //   display: none;
      // }
    }

    p {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      margin-bottom: 0;
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    &--logged-in {
      right: 0;
      @media (max-width: $post-shift-md) {
        display: none;
      }

      @media (max-width: $iphone-se) {
        display: flex;
      }
      @media (max-width: $iphone-xr) {
        display: flex;
      }
    }

    &--logo {
      left: 0;
      width: 150px;

      @media (max-width: $post-shift-xs) {
        width: 110px;
        height: auto;
      }
    }

    &--hamburger {
      right: $gutter-tablet;

      @media (max-width: $date-time-sm) {
        right: $gutter-mobile;
      }
    }
  }

  &__logo-btn {
    @extend %button-reset;
  }

  &__post-shift-btn {
    margin-right: 2.5rem;

    & .disabled {
      cursor: not-allowed;

      img {
        filter: grayscale(0.75);
      }

      & > p {
        cursor: not-allowed;
      }
    }

    & > .disabled img {
      
      filter: grayscale(1);
      cursor: disabled;
    }

    button {
      @extend %button-reset;
    }

    img {
      @media (max-width: $post-shift-xs) {
        width: 42px;
        height: auto;
        margin-top: -1px;
      }

      &.disabled {
        filter: grayscale(1);
        cursor: not-allowed;
      }
    }

    p {
      @media (max-width: $post-shift-xs) {
        font-size: 0.8rem;
        margin-top: -3px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &__location {
    @media (max-width: $date-time-sm) {
      display: none;
    }
    button {
      .icon-dropdown-arrow:before {
        font-size: 6px;
        vertical-align: middle;
        margin-top: 0;
      }
    }
  }

  &__location-btn {
    @extend %button-reset;
    font-size: 0.85rem;
    color: $grey-medium;
  }

  &__admin-link-btn {
    position: relative;
    margin-left: 25px;
    margin-top: 12px;
  }
}

.dropdown__content .header__post-shift-dropdown-item {
  display: none;
  color: $primary-red;
  border: 1px solid blue;

  @media (max-width: $post-shift-md) {
    display: block;
  }

  &:hover {
    background-color: $primary-red;
    color: white;
  }
}
.dropdown-img {
  width: 104px;
  height: 104px;
  border: 1px solid red;
}
