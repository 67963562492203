@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';

.general-modal {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 100;
  // @media (max-height: $modal-height-break) {
  //   display: block;
  //   padding: 0;
  //   height: auto;
  //   max-height: 100%;
  //   height: 100%;
  //   overflow-y: auto;
  //   -webkit-overflow-scrolling: touch;
  // }

  &__content {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    width: 94%;
    max-width: 1040px;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //   /* IE10+ CSS styles go here */
    //   transform: translate(-50%, -59%);
    // }

    @media (max-width: $date-time-sm) {
      width: 100%;
    }

    h1 {
      font-family: 'SF Pro Text', sans-serif;
      font-size: 2.3rem;
      text-align: center;
    }

    // @media (max-height: $modal-height-break) {
    //   top: 0;
    //   transform: translate(-50%, 0);
    //   min-height: 100%;
    // }
  }
}
