// Grid columns
$grid-gutter-width: 46px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  // sm: 100%,
  // md: 100%,
  // lg: 100%,
  xl: map-get($grid-breakpoints, xl) - $grid-gutter-width
);
