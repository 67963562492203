@import '~_stylesheets/components/_ModalComponents';

.comments-section {
  &__comment-wrap {
    margin-bottom: 1rem;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    // overflow-y: auto;
    // padding-right: 16px;
    // overflow-x: visible;

    &--small {
      max-height: 110px;
    }
    &--medium {
      max-height: 220px;
    }
    &--large {
      max-height: 440px;
    }
  }
}
// following code makes scroll bar shown without mouse hover
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
