@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

.modal-components {
  &__container {
    @include make-container();
  }

  &__row {
    @include make-row();
    margin-bottom: 1.5rem;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__col {
    @include make-col-ready();

    &--flex {
      width: auto;
      flex: 0 0 auto;
    }

    &--submit {
      text-align: center;
    }
  }

  &__label {
    font-family: $sf-pro;
    font-weight: 300;
    font-size: 16px;
    color: $label-color;
    margin-bottom: 0.25rem;
  }

  &__submit-btn {
    @extend %button-reset;
    background-color: lighten($primary-blue, 20%);
    font-family: $sf-pro;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    padding: 1rem;
    width: 174px;
    border-radius: 100px;
    cursor: default;

    @media (max-width: $post-shift-xs) {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    &--active {
      background-color: $primary-blue;
      cursor: pointer;
    }

    &--post {
      background-color: $primary-blue;
      cursor: pointer;
      width: auto;
      min-width: 112px;

      &:disabled {
        background-color: $button-inactive-color;
      }

      @media (max-width: $post-shift-xs) {
        width: 100%;
      }
    }

    &--back {
      background-color: white;
      border: 1px solid $primary-blue;
      color: $primary-blue;
      cursor: pointer;
      width: 142px;
      margin-right: 1.5rem;

      @media (max-width: $post-shift-xs) {
        width: 100%;
      }
    }

    &--delete {
      margin-right: 1.5rem;
      background-color: transparent;
      border: 1px solid $primary-red;
      color: $primary-red;
      cursor: pointer;

      &:hover {
        background-color: $primary-red;
        border: none;
        color: white;
      }
    }
  }

  &__confirm-details {
    font-family: $sf-pro;
    font-size: 1.14rem;
    color: $grey-dark-very;
    font-weight: 500;
    white-space: pre-line;
    max-height: 120px;
    overflow: auto;

    &--date-time {
      display: inline-block;
      margin-right: 2rem;
    }
  }
}
