@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
// @import '_stylesheets/components/_ModalComponents';

$dimensions: 46px;

.modal-components {
  &__col {
    &--names {
      @include make-col(6);
    }

    &--date-time {
      @include make-col(4);
    }
  }

  &__row {
    &--comment {
      margin-bottom: 1rem;
    }
  }

  &__confirm-details {
    &__date-time {
      margin-left: 1rem;
    }
  }

  &__submit-btn {
    width: 208px;
  }
}

.CoverConfirm__error-message {
  text-align: center;
  color: $primary-red;
  margin: auto;
}

.cover-comments2 {
  &__top-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__item-wrap {
    display: flex;
    flex-direction: column;
    min-width: 16%;
    margin-right: 20px;
  }

  &__item-name {
    color: rgba(128,133,139,255);
    font-size: 14px;
    font-weight: 300;
  }
  &__item-value {
    color: black;
    font-size: 14px;
    font-weight: 500;

  }
  &__skill-wrap {
    display: flex;
    flex-direction: row;
  }
  &__skill {
    color: black;
    background-color: rgba(242,242,248,255);
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    margin-right: 8px;
  }
}



