@import '~_stylesheets/vars';

$max-width: 635px;

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  font-size: 12px;
  //color: $grey-light;
  color: $lightFontColor;
  border-top: 1px solid $grey-light;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  z-index: 1;
  @media (max-width: $max-width) {
    height: 60px;
  }
}

.footer-detail--container {
  box-sizing: border-box;
  padding-top: 10px;
  float: right;
}

.footer-detail--link {
  top: 10px;

  & .footer-detail--link-ind {
    //color: $grey-light;
    color: $lightFontColor;
    //border-right: 1px solid $grey-light;
    border-right: 1px solid $lightFontColor;
    font-weight: 700;
    margin-right: 10px;
    padding-right: 15px;

    &:last-child {
      border: none;
      margin-right: 30px;
    }
  }
}