@import '~_stylesheets/vars';

.radio-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &__btn {
    margin-right: 0.5rem;
    position: relative;

    &__circle {
      width: 15px;
      height: 15px;
      background-color: white;
      background-size: 15px;
      border: 1px solid $grey-light;
      border-radius: 100%;

      &--active {
        background-color: $primary-blue;
        border-color: $primary-blue;
      }
    }

    &__check {
      display: none;
      position: absolute;
      top: 4px;
      left: 3px;
      width: 9px;
      height: 7px;
      background-image: url('../../assets/check.svg');
      background-size: 9px 7px;

      &--active {
        display: block;
      }
    }
  }

  &__label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 1.14rem;
    font-weight: 300;
  }
}
