@import '~_stylesheets/mixins';
@import '~_stylesheets/vars';

.dropdown {
  position: relative;
  display: inline-block;

  &--disabled {
    button {
      cursor: default;
    }
    .icon-dropdown-arrow {
      display: none;
    }
  }

  &--enlarged {
    img {
      //profile thumb img
      width: 48px;
      height: 48px;
      border-radius: 100%;
      //border: 1px solid red;

      @media (max-width: $post-shift-xs) {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }

    .icon-dropdown-arrow:before {
      font-size: 8px;
      vertical-align: top;
      margin-top: 5px;

      @media (max-width: $post-shift-xs) {
        font-size: 5px;
      }
    }
  }

  button {
    @extend %button-reset;

    img {
      //profile thumb img
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-top: 6px;
      margin-bottom: 10px;

      @media (max-width: $post-shift-xs) {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }

    p {
      @media (max-width: $post-shift-xs) {
        font-size: 0.8rem;
      }
    }

    .icon-dropdown-arrow:before {
      font-size: 8px;
      vertical-align: top;
      margin-top: 5px;

      @media (max-width: $post-shift-xs) {
        font-size: 5px;
      }
    }
  }

  &__content {
    max-height: 380px;
    overflow-y: auto;
    $border: 1px solid $grey-light;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0.5rem;
    background-color: white;
    z-index: 2;
    min-width: 100%;
    white-space: nowrap;
    border: $border;
    border-radius: $button-border-radius;
    max-height: 380px;
    overflow: auto;

    @media (max-width: $iphone-se) {
      margin-right: 5px;
    }
    @media (max-width: $iphone-xr) {
      margin-right: 5px;
    }

    &--right {
      right: 0;
      left: auto;
      transform: none;
      min-width: 0;
      @media (max-width: $post-shift-md) {
        right: -22px;
        margin-top: 1rem;
      }
    }

    &--left {
      left: 0;
      transform: none;
      min-width: 0;
    }

    a {
      display: block;
      text-align: center;
      padding: 1rem;
      border-bottom: $border;
      font-weight: 700;
      color: $grey-dark-very;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:not([href]):not([tabindex]):hover {
        text-decoration: none;
        color: white;
        background-color: $primary-blue;
      }
    }
  }
}
