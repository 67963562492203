@import '~_stylesheets/bootstrap/bootstrap-required';
@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';
@import '~_stylesheets/components/_ModalComponents';

.shift-details {
	
	&__btn-delete {
	 @font-face {
	  font-family: 'AvertaBold';
	  src: url('/fonts/averta/Averta-Bold.ttf');
	  }
	  font-family: 'AvertaBold';
	  font-size: 16px;
	  font-weight: 300;
		color: red;
		border-bottom: 2px solid red;
	}

	&__btn-call-off {
		@font-face {
	  font-family: 'AvertaRegular';
	  src: url('/fonts/averta/Averta-Regular.ttf');
	  }
	  font-family: 'AvertaRegular';
	  font-size: 16px;
	  font-weight: 300;
	  color: white;
    white-space: nowrap;
    padding: 8px;
    width: 174px;
    border-radius: 100px;
    background-color: rgba(242,242,248,255);
    //border: 1px solid rgba(115,120,126,255);
    border: none;
    color: rgba(115,120,126,255);
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;

    &:hover {
      background-color: transparent;
      //border: none;
      border: 1px solid rgba(115,120,126,255);
      color: rgba(115,120,126,255);
    }
	}

  &__btn-cover-shift {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 300;
    color: white;
    white-space: nowrap;
    padding: 8px;
    width: 210px;
    border-radius: 100px;
    background-color: rgba(61,174,255,255);
    //border: 1px solid rgba(61,174,255,255);
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;

    &:hover {
      background-color: transparent;
      //border: none;
      border: 1px solid rgba(61,174,255,255);
      color: rgba(61,174,255,255);
    }
  }

	 &__top-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__item-wrap {
    display: flex;
    flex-direction: column;
    min-width: 16%;
    margin-right: 20px;
  }

  &__item-name {
    color: rgba(128,133,139,255);
    font-size: 14px;
    font-weight: 300;
  }
  &__item-value {
    color: black;
    font-size: 14px;
    font-weight: 500;

  }
}
