@import './bootstrap/bootstrap-custom';

@import './vars';
@import './fonts';
@import './icons/css/icons.css';
@import './components/Buttons';

html {
  position: relative;

  overflow-x: hidden;

  height: 100%;
  background-color: white;

  font-family: $sf-pro;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  height: 100%;

  &.modal-open {
    overflow: hidden;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

button {
  &:focus:not(:focus-visible) { outline: none }
}
input {
  &:focus { outline: none }
}
