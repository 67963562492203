@import '~_stylesheets/vars';

.mobile-pin-input {
  max-width: 320px;
  margin: 70px auto 0 auto;
  text-align: center;

  & > input {
    text-align: center;
    -webkit-appearance: none;
    border-radius: 0;
    width: 60px;
    line-height: 60px;
    padding: 0;
    font-size: 56px;

    &:focus {
      background-color: lighten($primary-blue, 30%);
      outline: none;
      border-width: 1px;
      border-color: #dfdfdf;
      border-style: solid;
    }

    &::selection {
      background-color: transparent;
    }
  }

  & > input:not(:last-child) {
    margin-right: 15px;
  }
}
