@import '~_stylesheets/vars';
@import '~_stylesheets/mixins';

.top-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;

	//border: 1px solid red;
}
.top-left-section {
	width: 50%;

	//border: 1px solid red;
}
.top-right-section {
	width: 50%;

	//border: 1px solid red;
}
.bottom-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 300px;

	//border: 1px solid red;
}
.bottom-left-section {
	width: 40%;

	//border: 1px solid red;
}
.bottom-right-section {
	width: 60%;

	//border: 1px solid red;
}


.section-title {
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
	margin-left: 10px;

	//border: 1px solid red;
}
.calendar-time-container {
	display: flex;
	flex-direction: row;
	margin-top: 0px;
	margin-left: 10px;
	margin-right: 10px;
	height: 40px;

	//border: 1px solid red;
}
.calendar-container {
	display: flex;
	flex-direction: row;
	width: 35%;
	border: 1px solid rgba(209,209,209,255);
	//border-radius: 2px;
}
.calendar-icon {
	width: 13px;
	height: 13px;
	margin: auto;
	color: rgba(209,209,209,255);
}
.calendar-text {
	margin: auto;
	font-size: 14px;
	font-weight: 400;
}
.time-container {
	display: flex;
	flex-direction: row;
	width: 65%;
	border: 1px solid rgba(209,209,209,255);
	//border-radius: 2px;
	border-left: none;

	.icon-calendar, .icon-clock {
    margin-top: 8px;
    margin-left: 5px;
    color: rgba(209,209,209,255);
  }
}
.time-icon {
	width: 15px;
	height: 15px;
	margin: auto;
}
.time-text {
	//margin: auto;
	margin-top: 8px;
	margin-left: 8px;
	font-size: 14px;
	font-weight: 400;
	//border: 1px solid red;
}
.job-title-name {
	width: 65%;
	height: 40px;
	margin-left: 10px;
	border: 1px solid rgba(209,209,209,255);
	padding-top: 8px;
	padding-left: 10px;
	
}
.job-title-text {
	margin: auto;
	font-size: 14px;
	font-weight: 400;
}
.call-off-reasons-1 {
	width: 70%;
	height: 200px;
	margin-left: 10px;
	overflow-y: scroll;

	border: 1px solid rgba(209,209,209,255);
	
}
.call-off-reasons-2 {
	width: 90%;
	height: 80px;
	margin-left: 10px;
	overflow-y: scroll;

	border: 1px solid rgba(209,209,209,255);
	
}
.top-section-preview {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 80px;
	margin-top: 50px;

	//border: 1px solid red;
}

.bottom-section-preview {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 80px;

	//border: 1px solid red;
}

.section-title-preview {
	font-size: 14px;
	font-weight: 500;
	margin-top: 10px;
	padding-left: 10px;
	color: rgba(144,144,144,255);

	//border: 1px solid red;
}

.section-contents {
	display: flex;
	flex-direction: row;

	//border: 1px solid red;
}

.item-container {
	display: flex;
	flex-direction: row;
	width: 30%;

	//border: 1px solid blue;
}
.item-name {
	font-size: 14px;
	font-weight: 600;
	height: 100%;
	padding-top: 3px;
	padding-left: 10px;

	//border: 1px solid red;
}
.item-value {
	font-size: 14px;
	font-weight: 400;
	height: 100%;
	padding-top: 3px;
	padding-left: 5px;

	//border: 1px solid red;
}

.name-container {
	display: flex;
	flex-direction: column;
	width: 20%;
}
.job-container {
	display: flex;
	flex-direction: column;
	width: 20%;
}
.reason1-container {
	display: flex;
	flex-direction: column;
	width: 25%;
}
.reason2-container {
	display: flex;
	flex-direction: column;
	width: 35%;
}
.bottom-item-name {
	font-size: 14px;
	font-weight: 500;
	margin-top: 10px;
	padding-left: 10px;
	color: rgba(144,144,144,255);

	//border: 1px solid red;
}
.bottom-item-value {
	font-size: 14px;
	font-weight: 600;
	padding-left: 10px;

	//border: 1px solid red;
}
.button-next {
	width: 160px;
	height: 36px;
	border-radius: 18px;
	color: white;
	background-color: rgba(0,177,255,255);
	font-size: 14px;
	font-weight: 600;


	&:last-child {
		margin-left: 30px;
	}

	&:hover {
		color: rgba(0,177,255,255);
		background-color: white;
		border: 1px solid rgba(0,177,255,255);
	}

	//border: 1px solid red;
}
.button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 50px;
}
.button-preview {
	width: 180px;
	height: 36px;
	border-radius: 18px;
	color: white;
	background-color: rgba(0,177,255,255);
	font-size: 14px;
	font-weight: 600;


	&:last-child {
		margin-left: 30px;
	}

	&:hover {
		color: rgba(0,177,255,255);
		background-color: white;
		border: 1px solid rgba(0,177,255,255);
	}

	//border: 1px solid red;
}

.button-last-screen {
	width: 180px;
	height: 36px;
	border-radius: 18px;
	color: white;
	background-color: rgba(0,177,255,255);
	font-size: 14px;
	font-weight: 600;


	&:last-child {
		margin-left: 30px;
	}

	&:hover {
		color: rgba(0,177,255,255);
		background-color: white;
		border: 1px solid rgba(0,177,255,255);
	}

	//border: 1px solid red;
}

.called-off-top-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 80px;
	margin-left: 120px;

	//border: 1px solid red;
}

.called-off-bottom-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 80px;
	margin-left: 120px;

	//border: 1px solid red;
}

.left-section {
	display: flex;
	flex-direction: column;
	width: 20%;
}

.right-section {
	display: flex;
	flex-direction: column;
	width: 70%;
	//border: 1px solid green;
}

.date-time-container {
	display: flex;
	flex-direction: row;
	width: 100%;

	//border: 1px solid blue;
}

.call-off-item-name {
	font-size: 14px;
	font-weight: 600;
	padding-left: 10px;

	//border: 1px solid red;
}
.call-off-item-value {
	font-size: 14px;
	font-weight: 500;
	margin-top: 0px;
	padding-left: 5px;
	margin-right: 5px;
	color: rgba(144,144,144,255);

	//border: 1px solid red;
}
.description-container {
	display: flex;
	flex-direction: row;
	width: 100%;

	//border: 1px solid blue;
}
.button-called-off-delete-wrap {
	display: flex;
	flex-direction: column;
}
.button-called-off {
	width: 180px;
	height: 36px;
	border-radius: 18px;
	color: rgba(239,103,98,255);
	background-color: rgba(255,223,220,255);
	font-size: 14px;
	font-weight: 600;
	margin-top: 20px;
	margin-left: 40%;


	&:last-child {
		//margin-left: 30px;
	}

	&:hover {
		color: rgba(239,103,98,255);
		background-color: white;
		border: 1px solid rgba(239,103,98,255);
	}

	//border: 1px solid red;
}
.delete-btn-wrap {
	display: flex;
	margin-top: 10px;
	justify-content: center;
	//border: 1px solid red;
}
.button-delete-shift {
	@font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 16px;
  font-weight: 300;
	color: red;
	border-bottom: 2px solid red;
}


